import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgTrophy(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 1.8125C7.60466 1.8125 6.68218 2.6999 6.55078 3.8125H5.25C4.29252 3.8125 3.5 4.60502 3.5 5.5625V8.5625C3.5 10.3485 4.96403 11.8125 6.75 11.8125H6.85449C7.52788 13.8737 9.2997 15.358 11.5 15.6602V17.3125H9.75C7.96403 17.3125 6.5 18.7765 6.5 20.5625V21.0625C6.50002 21.2614 6.57904 21.4522 6.71969 21.5928C6.86034 21.7335 7.05109 21.8125 7.25 21.8125H17.25C17.4489 21.8125 17.6397 21.7335 17.7803 21.5928C17.921 21.4522 18 21.2614 18 21.0625V20.5625C18 18.7765 16.536 17.3125 14.75 17.3125H13V15.6602C15.2003 15.358 16.9721 13.8737 17.6455 11.8125H17.75C19.536 11.8125 21 10.3485 21 8.5625V5.5625C21 4.60502 20.2075 3.8125 19.25 3.8125H17.9492C17.8178 2.6999 16.8953 1.8125 15.75 1.8125H8.75ZM8.75 3.3125H15.75C16.1732 3.3125 16.5 3.63925 16.5 4.0625V4.43945C16.4868 4.51997 16.4868 4.6021 16.5 4.68262V10.0625C16.5 12.4034 14.6279 14.2794 12.293 14.3037C12.2643 14.3021 12.2357 14.3021 12.207 14.3037C9.87213 14.2794 8 12.4034 8 10.0625V4.68555C8.01323 4.60503 8.01323 4.5229 8 4.44238V4.0625C8 3.63925 8.32675 3.3125 8.75 3.3125ZM5.25 5.3125H6.5V10.0625C6.5 10.1365 6.5392 10.1981 6.54199 10.2715C5.67213 10.1658 5 9.4635 5 8.5625V5.5625C5 5.41498 5.10248 5.3125 5.25 5.3125ZM18 5.3125H19.25C19.3975 5.3125 19.5 5.41498 19.5 5.5625V8.5625C19.5 9.4635 18.8279 10.1658 17.958 10.2715C17.9608 10.1981 18 10.1365 18 10.0625V5.3125ZM9.75 18.8125H14.75C15.636 18.8125 16.326 19.464 16.4502 20.3125H8.0498C8.17403 19.464 8.86398 18.8125 9.75 18.8125Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgTrophy;
