import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgMoneyBag(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3401 11.1425C10.2986 11.1425 9.45435 11.9867 9.45435 13.0282C9.45435 14.0696 10.2986 14.9139 11.3401 14.9139H12.6258C13.0045 14.9139 13.3115 15.2209 13.3115 15.5996C13.3115 15.9783 13.0045 16.2853 12.6258 16.2853H10.6972C10.3658 16.2853 10.0972 16.5539 10.0972 16.8853C10.0972 17.2167 10.3658 17.4853 10.6972 17.4853H11.3844V17.5287C11.3844 17.8601 11.653 18.1287 11.9844 18.1287C12.3158 18.1287 12.5844 17.8601 12.5844 17.5287V17.4853H12.6258C13.6672 17.4853 14.5115 16.6411 14.5115 15.5996C14.5115 14.5581 13.6672 13.7139 12.6258 13.7139H11.3401C10.9613 13.7139 10.6543 13.4069 10.6543 13.0282C10.6543 12.6494 10.9613 12.3425 11.3401 12.3425H11.9623C11.9696 12.3427 11.977 12.3429 11.9844 12.3429C11.9884 12.3429 11.9923 12.3428 11.9963 12.3427C11.9997 12.3427 12.0032 12.3426 12.0066 12.3425H13.2686C13.6 12.3425 13.8686 12.0738 13.8686 11.7425C13.8686 11.4111 13.6 11.1425 13.2686 11.1425H12.5844V11.1C12.5844 10.7686 12.3158 10.5 11.9844 10.5C11.653 10.5 11.3844 10.7686 11.3844 11.1V11.1425H11.3401Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31064 2.43196C9.51225 2.36335 9.73512 2.40676 9.89625 2.54601L11.9906 4.35592L14.1709 2.53904C14.3315 2.40516 14.5503 2.36459 14.7483 2.43196L16.9524 3.18196C17.1213 3.23945 17.2559 3.36915 17.3196 3.53585C17.3833 3.70255 17.3694 3.88895 17.2819 4.04444L15.4067 7.37358C16.7014 8.35397 17.8806 9.61863 18.7702 10.9702C19.7705 12.4897 20.4412 14.1757 20.4412 15.7329C20.4412 17.8589 19.5247 19.3729 17.9534 20.3206C16.4286 21.2401 14.3349 21.6 11.9841 21.6C9.63336 21.6 7.53963 21.2401 6.01488 20.3206C4.4435 19.3729 3.52699 17.8589 3.52699 15.7329C3.52699 14.1757 4.19776 12.4897 5.198 10.9702C6.10219 9.59651 7.3055 8.31258 8.62524 7.32565L6.77707 4.04443C6.68949 3.88895 6.67567 3.70255 6.73935 3.53585C6.80304 3.36915 6.93762 3.23945 7.10656 3.18196L9.31064 2.43196ZM15.8816 4.08519L14.2962 6.89996H9.76273L8.17729 4.08519L9.37173 3.67874L11.5912 5.59679C11.8134 5.78884 12.142 5.79179 12.3676 5.60377L14.6804 3.67645L15.8816 4.08519ZM9.60063 8.09996C8.29737 9.02098 7.08949 10.2791 6.20034 11.6299C5.27152 13.041 4.72699 14.4966 4.72699 15.7329C4.72699 17.4478 5.43129 18.5673 6.63459 19.293C7.88453 20.0468 9.71936 20.4 11.9841 20.4C14.2489 20.4 16.0837 20.0468 17.3336 19.293C18.5369 18.5673 19.2412 17.4478 19.2412 15.7329C19.2412 14.4966 18.6967 13.041 17.7679 11.6299C16.8787 10.2791 15.6709 9.02098 14.3676 8.09996H9.60063Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgMoneyBag;
