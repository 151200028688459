import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgCookie(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C10.321 1.66675 10.6381 1.68494 10.9501 1.72037C11.0608 1.73294 11.1618 1.78931 11.2307 1.87688C11.2995 1.96446 11.3304 2.07596 11.3165 2.18647C11.3026 2.2966 11.2954 2.40898 11.2954 2.52323C11.2954 3.87251 12.2997 4.98791 13.6013 5.16169C13.7876 5.18656 13.9342 5.33309 13.9591 5.51938C14.1333 6.8205 15.2485 7.82416 16.5974 7.82416C16.9256 7.82416 17.239 7.76496 17.5282 7.65703C17.6391 7.61564 17.7623 7.62337 17.8673 7.67831C17.9721 7.73325 18.0486 7.8302 18.0778 7.94494C18.2446 8.60275 18.3332 9.29141 18.3332 10.0001C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10.0001ZM9.99984 2.50008C5.8577 2.50008 2.49984 5.85795 2.49984 10.0001C2.49984 14.1422 5.8577 17.5001 9.99984 17.5001C14.142 17.5001 17.4998 14.1422 17.4998 10.0001C17.4998 9.51183 17.4533 9.03475 17.3643 8.573C17.1173 8.62833 16.8606 8.6575 16.5974 8.6575C14.9318 8.6575 13.5389 7.49289 13.1875 5.93344C11.6273 5.58258 10.4621 4.18929 10.4621 2.52323C10.4621 2.52018 10.4621 2.51713 10.4621 2.51408C10.3093 2.5048 10.1551 2.50008 9.99984 2.50008Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M8.33325 7.08325C8.33325 7.77361 7.77361 8.33325 7.08325 8.33325C6.39289 8.33325 5.83325 7.77361 5.83325 7.08325C5.83325 6.39289 6.39289 5.83325 7.08325 5.83325C7.77361 5.83325 8.33325 6.39289 8.33325 7.08325Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M11.6665 9.58325C11.6665 10.2736 11.1068 10.8333 10.4165 10.8333C9.72617 10.8333 9.1665 10.2736 9.1665 9.58325C9.1665 8.89292 9.72617 8.33325 10.4165 8.33325C11.1068 8.33325 11.6665 8.89292 11.6665 9.58325Z"
          fill="currentColor"
        />
        <path
          id="Vector_4"
          d="M14.9999 11.6666C14.9999 12.1268 14.6268 12.4999 14.1666 12.4999C13.7063 12.4999 13.3333 12.1268 13.3333 11.6666C13.3333 11.2063 13.7063 10.8333 14.1666 10.8333C14.6268 10.8333 14.9999 11.2063 14.9999 11.6666Z"
          fill="currentColor"
        />
        <path
          id="Vector_5"
          d="M10.8333 13.75C10.8333 14.4403 10.2736 15 9.58325 15C8.89292 15 8.33325 14.4403 8.33325 13.75C8.33325 13.0597 8.89292 12.5 9.58325 12.5C10.2736 12.5 10.8333 13.0597 10.8333 13.75Z"
          fill="currentColor"
        />
        <path
          id="Vector_6"
          d="M6.66667 11.6666C6.66667 12.1268 6.29357 12.4999 5.83333 12.4999C5.3731 12.4999 5 12.1268 5 11.6666C5 11.2063 5.3731 10.8333 5.83333 10.8333C6.29357 10.8333 6.66667 11.2063 6.66667 11.6666Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
export default SvgCookie;
