import type { SvgIconPropsWithChildren } from '../components/SvgIcon/index.js';

import React, { createElement } from 'react';

import { SvgIcon } from '../components/SvgIcon/index.js';

function SvgNumberBlocks(props: SvgIconPropsWithChildren) {
  return createElement(
    SvgIcon,
    props,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24">
      <g id="surface1">
        <path
          fill="currentColor"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
          }}
          d="M 11.519531 9.542969 L 13.011719 9.542969 L 13.011719 4.089844 L 11.6875 4.089844 L 9.816406 5.617188 L 10.582031 6.558594 L 11.199219 6.046875 C 11.324219 5.9375 11.4375 5.820312 11.542969 5.695312 C 11.527344 5.988281 11.519531 6.308594 11.519531 6.65625 Z M 11.519531 9.542969 "
        />
        <path
          fill="currentColor"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
          }}
          d="M 8.488281 20.453125 L 4.535156 20.453125 L 4.535156 19.484375 L 5.867188 18.136719 C 6.246094 17.742188 6.496094 17.46875 6.613281 17.324219 C 6.707031 17.210938 6.789062 17.082031 6.855469 16.949219 C 6.902344 16.847656 6.925781 16.734375 6.925781 16.621094 C 6.929688 16.558594 6.917969 16.496094 6.894531 16.4375 C 6.867188 16.378906 6.832031 16.328125 6.785156 16.285156 C 6.675781 16.199219 6.539062 16.15625 6.398438 16.164062 C 6.214844 16.167969 6.035156 16.21875 5.878906 16.308594 C 5.667969 16.4375 5.46875 16.582031 5.285156 16.746094 L 4.472656 15.796875 C 4.6875 15.59375 4.925781 15.414062 5.179688 15.257812 C 5.375 15.148438 5.585938 15.066406 5.804688 15.011719 C 6.054688 14.949219 6.3125 14.921875 6.566406 14.925781 C 6.890625 14.917969 7.214844 14.980469 7.511719 15.109375 C 7.773438 15.222656 8 15.40625 8.160156 15.640625 C 8.308594 15.871094 8.390625 16.140625 8.386719 16.414062 C 8.394531 16.816406 8.28125 17.207031 8.058594 17.542969 C 7.929688 17.742188 7.777344 17.925781 7.613281 18.097656 C 7.429688 18.292969 7.035156 18.660156 6.429688 19.203125 L 6.429688 19.238281 L 8.492188 19.238281 Z M 8.488281 20.453125 "
        />
        <path
          fill="currentColor"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
          }}
          d="M 18.90625 17.058594 C 19.113281 16.8125 19.222656 16.503906 19.21875 16.183594 C 19.222656 16 19.183594 15.820312 19.097656 15.65625 C 19.011719 15.496094 18.886719 15.359375 18.734375 15.257812 C 18.335938 15.011719 17.871094 14.894531 17.40625 14.921875 C 17.054688 14.917969 16.707031 14.960938 16.367188 15.046875 C 16.050781 15.140625 15.75 15.285156 15.480469 15.472656 L 16.09375 16.457031 C 16.414062 16.238281 16.789062 16.117188 17.175781 16.105469 C 17.332031 16.097656 17.484375 16.136719 17.621094 16.214844 C 17.675781 16.246094 17.71875 16.296875 17.746094 16.351562 C 17.777344 16.410156 17.789062 16.472656 17.785156 16.535156 C 17.785156 16.917969 17.414062 17.109375 16.664062 17.109375 L 16.320312 17.109375 L 16.320312 18.214844 L 16.65625 18.214844 C 16.882812 18.210938 17.105469 18.230469 17.328125 18.277344 C 17.464844 18.300781 17.597656 18.367188 17.699219 18.460938 C 17.78125 18.554688 17.824219 18.679688 17.816406 18.804688 C 17.820312 18.890625 17.804688 18.972656 17.765625 19.050781 C 17.726562 19.125 17.667969 19.1875 17.597656 19.234375 C 17.378906 19.339844 17.132812 19.386719 16.890625 19.367188 C 16.660156 19.367188 16.429688 19.335938 16.207031 19.28125 C 15.953125 19.222656 15.707031 19.128906 15.476562 19.011719 L 15.476562 20.238281 C 15.738281 20.34375 16.011719 20.417969 16.289062 20.46875 C 16.558594 20.511719 16.832031 20.535156 17.105469 20.53125 C 17.691406 20.566406 18.277344 20.414062 18.777344 20.097656 C 18.96875 19.960938 19.125 19.78125 19.230469 19.574219 C 19.335938 19.363281 19.386719 19.128906 19.378906 18.894531 C 19.378906 18.152344 18.921875 17.722656 18.011719 17.605469 L 18.011719 17.582031 C 18.359375 17.5 18.667969 17.316406 18.90625 17.058594 Z M 18.90625 17.058594 "
        />
        <path
          fill="currentColor"
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fillOpacity: 1,
          }}
          d="M 6 0.707031 C 5.636719 0.707031 5.34375 1 5.34375 1.363281 L 5.34375 11.617188 L 1.089844 11.617188 C 0.730469 11.617188 0.4375 11.910156 0.4375 12.273438 L 0.4375 23.179688 C 0.4375 23.542969 0.730469 23.835938 1.089844 23.835938 L 22.910156 23.835938 C 23.269531 23.835938 23.5625 23.542969 23.5625 23.179688 L 23.5625 12.273438 C 23.5625 11.910156 23.269531 11.617188 22.910156 11.617188 L 18.652344 11.617188 L 18.652344 1.363281 C 18.652344 1 18.359375 0.707031 18 0.707031 Z M 17.34375 11.617188 L 6.652344 11.617188 L 6.652344 2.019531 L 17.34375 2.019531 Z M 1.746094 22.527344 L 1.746094 12.925781 L 11.34375 12.925781 L 11.34375 22.527344 Z M 12.652344 22.527344 L 12.652344 12.925781 L 22.253906 12.925781 L 22.253906 22.527344 Z M 12.652344 22.527344 "
        />
      </g>
    </svg>
  );
}
export default SvgNumberBlocks;
